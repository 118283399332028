input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
  -webkit-transition-delay: 9999s;
}

input:-webkit-autofill {
  -webkit-text-fill-color: #F78243 !important;
}

textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: none;
  -webkit-text-fill-color: #F78243;
  transition: background-color 5000s ease-in-out 0s;
  caret-color: #F78243;
}


.display {
  opacity: 1;
  animation: 3s fadeInOpacity 1;
  background:
    radial-gradient(circle, transparent 5%, var(--color-v) 75%),
    linear-gradient(to right, var(--color), var(--color)),
    var(--image2);
  background-position: center center;
  background-size: cover;
  background-blend-mode:
    var(--blend-top, normal),
    var(--blend-bottom, multiply),
    normal;

  --image: url(../Home/assets/IMG_1513.jpeg);
  --image2: url(../Home/assets/IMG_1513.jpeg);

  --color-v: black;
  --color: grey;

  flex: 1;
  width: 100vw;
  height: 120vh;
}

.displayNight {
  opacity: 1;
  animation: 3s fadeInOpacity 1;
  background:
    radial-gradient(circle, transparent 5%, var(--color-v) 75%),
    linear-gradient(to right, var(--color), var(--color)),
    var(--image2);
  background-position: center center;
  background-size: cover;
  background-blend-mode:
    var(--blend-top, normal),
    var(--blend-bottom, multiply),
    normal;

  --image: url(../Home/assets/IMG_1513.jpeg);
  --image2: url(../Home/assets/IMG_1513.jpeg);

  --color-v: black;
  --color: blue;

  flex: 1;
  width: 100vw;
  height: 120vh;
}

.displaySafari {
  opacity: 1;
  background:
    radial-gradient(circle, transparent 5%, var(--color-v) 75%),
    linear-gradient(to right, var(--color), var(--color)),
    var(--image2);
  background-position: center center;
  background-size: cover;
  background-blend-mode:
    var(--blend-top, normal),
    var(--blend-bottom, multiply),
    normal;

  --image: url(../Home/assets/IMG_1513.jpeg);
  --image2: url(../Home/assets/IMG_1513.jpeg);

  --color-v: black;
  --color: grey;

  flex: 1;
  width: 100vw;
  height: 120vh;
}

.displaySafariNight {
  opacity: 1;
  background:
    radial-gradient(circle, transparent 5%, var(--color-v) 75%),
    linear-gradient(to right, var(--color), var(--color)),
    var(--image2);
  background-position: center center;
  background-size: cover;
  background-blend-mode:
    var(--blend-top, normal),
    var(--blend-bottom, multiply),
    normal;

  --image: url(../Home/assets/IMG_1513.jpeg);
  --image2: url(../Home/assets/IMG_1513.jpeg);

  --color-v: black;
  --color: blue;

  flex: 1;
  width: 100vw;
  height: 120vh;
}

.displayIE {
  opacity: 1;
  background:
    radial-gradient(circle, transparent 5%, var(--color-v) 75%),
    linear-gradient(to right, var(--color), var(--color)),
    var(--image2);
  background-position: center center;
  background-size: cover;
  background-blend-mode:
    var(--blend-top, normal),
    var(--blend-bottom, multiply),
    normal;

  --image: url(../Home/assets/IMG_1513.jpeg) no-repeat;
  --image2: url(../Home/assets/IMG_1513.jpeg) no-repeat;

  --color-v: black;
  --color: grey;

  flex: 1;
  width: 100vw;
  height: 120vh;
}

.displayIENight {
  opacity: 1;
  background:
    radial-gradient(circle, transparent 5%, var(--color-v) 75%),
    linear-gradient(to right, var(--color), var(--color)),
    var(--image2);
  background-position: center center;
  background-size: cover;
  background-blend-mode:
    var(--blend-top, normal),
    var(--blend-bottom, multiply),
    normal;

  --image: url(../Home/assets/IMG_1513.jpeg) no-repeat;
  --image2: url(../Home/assets/IMG_1513.jpeg) no-repeat;

  --color-v: black;
  --color: blue;

  flex: 1;
  width: 100vw;
  height: 120vh;
}

@keyframes fadeInOpacity {
  0% {
    background-color: black;
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeInOpacity {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}